@import "../global";
@import "../variables";

.notif-popover {
  width: 500px !important;
  max-height: 400px !important;
  display: flex;
  flex-direction: column;

  .header {
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px 20px;
    .title {
      font-weight: 700;
      font-size: 25px;
    }
  }
}

.notifs-more {
  width: 300px !important;
  margin: 10px 0;

  .potona {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #eeeeee;
    }
  }
}

.notifs-wrapper {
  overflow-y: scroll;
  height: 100%;
  .notif-entry {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    margin: 0;

    .image {
      min-width: 50px !important;
      height: 50px !important;
      border-radius: 50px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin: 0 10px;
    }

    .notif {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      &-title {
        font-weight: 700;
      }

      &-content {
        font-weight: 300 !important;
        font-size: 12px !important;

        &-unread {
          color: $primary;
        }
      }
    }

    .unread-circle {
      min-width: 10px;
      min-height: 10px;
      border-radius: 10px;
      margin: 0 10px;
      background: $primary;
    }

    &:hover {
      background: #eeeeee;
    }

    .notif-menu {
      position: absolute;
      z-index: 50;
      top: 50%;
      right: 30px !important;
      left: unset !important;
      transform: translateY(-50%);

      &-show {
        display: block;
      }
      &-hide {
        display: none;
      }
    }
  }
}

html[dir="rtl"] {
  .notif-entry {
    .notif-menu {
      right: unset !important;
      left: 30px !important;
    }
  }
}
