@import "../global";
@import "../variables";

.msg-loader {
	// overflow: auto;
	height: 100%;
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
}
.message-container {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 20px;

	&-sent {
		justify-content: flex-end;
	}

	.message {
		margin: 0;
		max-width: 80%;
		padding: 10px;
		text-align: initial;
		overflow-wrap: break-word;
		word-wrap: break-word;

		-ms-word-break: break-all;
		/* This is the dangerous one in WebKit, as it breaks things wherever */
		word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;

		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;

		&-sent {
			background: white;
			border: 1px solid #9999995b;
			border-radius: 20px 20px 0 20px;
		}

		&-received {
			background: $primary;
			//   border: 1px solid #9999995b;
			color: white;
			border-radius: 0 20px 20px 20px;

			.file-name-size {
				.size {
					color: white !important;
				}
			}

			.zoom-link {
				color: white !important;
			}
		}
	}

	.message-menu {
		color: #9999995b;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		margin: 0 5px;

		&:hover {
			color: #999999;
		}
	}

	.ant-avatar {
		// background: red;
		align-self: flex-start;
	}

	.sent-indicators {
		//   background: red;
		height: 15px;
		margin: 0 5px;
		// margin: 0 5px;
		align-self: flex-end;
	}

	.image-message-wrapper {
		// background: red;
		.text-with-image {
			margin-bottom: 10px;
		}

		.image-message {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			width: 200px;
			height: 200px;
			border-radius: 10px;
			transition: all 0.2s ease;
		}
	}

	.file-message-wrapper {
		.text-with-file {
			margin-bottom: 10px;
		}

		.file-message {
			background: rgba($color: #000000, $alpha: 0.1);
			padding: 10px;
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			// cursor: pointer;

			.file-type {
				min-width: 50px;
				min-height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-transform: uppercase;
				font-weight: 900;
				background: #999999;
				color: white;
				border-radius: 10px;
				border: 3px solid white;

				&-pdf {
					background: #e74c3c;
				}
				&-doc {
					background: #3498db;
				}
				&-ppt {
					background: #c0392b;
				}
				&-xls {
					background: #27ae60;
				}
				&-txt {
					background: #999999;
				}
			}

			.file-name-size {
				min-width: 50px;
				margin: 0 10px;

				.name {
					font-weight: 700;
					font-size: 13px;
				}

				.size {
					color: #999999;
					font-size: 10px;
					font-weight: 300;
				}
			}

			.download-btn {
				cursor: pointer;
			}
		}
	}

	.zoom-message {
		// background: red;

		.zoom {
			&-topic {
				padding: 5px 10px;
				font-weight: 700;
				font-size: 10px;
			}
			&-link {
				// background: black;
				padding: 10px 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 17px;
				cursor: pointer;
				color: $primary;
			}
			&-user {
				display: flex;
				align-items: center;
				.avatar {
					margin: 0 5px;
					min-width: 15px;
					min-height: 15px;
					max-width: 15px;
					max-height: 15px;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					border-radius: 20px;
				}
				.name {
					font-size: 10px;
					text-transform: capitalize;
				}
			}
		}
	}

	.task-message-wrapper {
		.text-with-task {
			margin-bottom: 10px;
		}

		.task-message {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.task {
				&-checkbox {
					margin: 0 10px;
				}

				&-infos {
					background: rgba($color: #000000, $alpha: 0.1);
					padding: 10px;
					border-radius: 10px;

					&-pending {
						border-left: 10px solid #ffb800;
					}
					&-finished {
						border-left: 10px solid #00bd2a;
					}

					.task {
						&-title {
							font-weight: 700;
							font-size: 15px;
						}
						&-desc {
							font-weight: 300;
							font-size: 10px;
						}

						&-footer {
							width: 100%;
							display: flex;
							justify-content: space-between;
							margin-top: 10px;

							.task-user {
								margin-bottom: 5px;
								display: flex;
								justify-content: center;
								align-items: center;
								font-size: 10px;
								text-transform: capitalize;

								.ant-avatar {
									margin: 0 5px;
									min-width: 20px !important;
									min-height: 20px !important;
									max-width: 20px !important;
									max-height: 20px !important;
								}
							}
							.task-deadline {
								font-weight: 300;
								font-size: 10px;
								text-transform: capitalize;
							}
						}
					}
				}
			}
		}
	}
}

.message-images {
	width: 100%;
	padding: 5px;
	border-radius: 10px;
	.file-message-wrapper {
		.text-with-file {
			margin-bottom: 10px;
		}

		.file-message {
			background: rgba($color: #000000, $alpha: 0.1);
			padding: 10px;
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			// cursor: pointer;

			.file-type {
				min-width: 50px;
				min-height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-transform: uppercase;
				font-weight: 900;
				background: #999999;
				color: white;
				border-radius: 10px;
				border: 3px solid white;

				&-pdf {
					background: #e74c3c;
				}
				&-doc {
					background: #3498db;
				}
				&-ppt {
					background: #c0392b;
				}
				&-xls {
					background: #27ae60;
				}
				&-txt {
					background: #999999;
				}
			}

			.file-name-size {
				min-width: 50px;
				margin: 0 10px;

				.name {
					font-weight: 700;
					font-size: 13px;
				}

				.size {
					color: #999999;
					font-size: 10px;
					font-weight: 300;
				}
			}

			.download-btn {
				cursor: pointer;
			}
		}
	}
	.image-message-wrapper {
		// background: red;
		.text-with-image {
			margin-bottom: 10px;
		}

		.image-message {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			width: 123px;
			height: 123px;
			border-radius: 10px;
			transition: all 0.2s ease;
		}
	}
}
.task-message-wrapper {
	.text-with-task {
		margin-bottom: 10px;
	}

	.task-message {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.task {
			&-checkbox {
				margin: 0 10px;
			}

			&-infos {
				background: rgba($color: #000000, $alpha: 0.1);
				padding: 10px;
				border-radius: 10px;

				&-pending {
					border-left: 10px solid #ffb800;
				}
				&-finished {
					border-left: 10px solid #00bd2a;
				}

				.task {
					&-title {
						font-weight: 700;
						font-size: 15px;
					}
					&-desc {
						font-weight: 300;
						font-size: 10px;
					}

					&-footer {
						width: 100%;
						display: flex;
						justify-content: space-between;
						margin-top: 10px;

						.task-user {
							margin-bottom: 5px;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 10px;
							text-transform: capitalize;

							.ant-avatar {
								margin: 0 5px;
								min-width: 20px !important;
								min-height: 20px !important;
								max-width: 20px !important;
								max-height: 20px !important;
							}
						}
						.task-deadline {
							font-weight: 300;
							font-size: 10px;
							text-transform: capitalize;
						}
					}
				}
			}
		}
	}
}
html[dir="rtl"] {
	.message-container {
		.message-sent {
			border-radius: 20px 20px 20px 0;
		}

		.message-received {
			border-radius: 20px 0 20px 20px;
		}
	}
}
