.home {
	padding-top: 100px;
	background-color: white;
	height: 100%;
	.header {
		text-align: center;
		background-image: linear-gradient(to top, white, rgba(0, 0, 0, 0));
		.main-svg {
			width: 80%;
			margin: auto;
		}
	}
	.section__main {
		height: 427px;
		background: linear-gradient(180deg, rgba(242, 249, 255, 0.12) 0%, #f2f9ff 100%);
		display: flex;
		padding-top: 100px;
		margin-bottom: 100px;
		align-items: center;
		justify-content: flex-end;

		&--main-svg {
			width: 100%;
			align-self: flex-end;
			margin-top: 20px;
		}

		&--slogan,
		&--title {
			align-items: center;
			text-align: center;
			text-transform: uppercase;
			color: #1e266d;
		}
	}
	.section__about-us {
		text-align: center;

		.lead {
			text-align: center;
		}
		.image {
			width: 100%;
			height: 500px;
			border-radius: 20px;
			margin: 20px 0;
			position: relative;

			&--text-holder {
				background: rgba(0, 0, 0, 0.6);
				color: white;
				width: 80%;
				height: 80%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 20px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				font-size: 50px;
				font-weight: 900;
			}
		}
		.button {
			background-color: #458ff6 !important;
			border-color: #458ff6 !important;
			height: 45px;
		}
	}

	.content {
		margin-top: 30px;
		text-align: center;
		font-size: 15px;
		.btn {
			background-color: #458ff6;
			border-radius: 5px;
			color: white;
			text-align: center;
			align-items: center;
			padding: 10px;
		}
	}
}

html[dir="rtl"] {
	.section__main {
		&--main-svg {
			width: 100%;
			align-self: flex-end;
			margin-top: 20px;
		}
	}
}
