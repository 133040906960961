@import "variables";

.edit-profile-page {
  padding: 50px 0;

  .edit {
    &-card {
      height: 100%;
      width: 100%;
      background: white;
      border-radius: 20px;
      box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.15);
      padding: 20px 10px;
    }

    &-key {
      padding: 5px 10px;
      margin: 10px 0;
      font-weight: 700;
      font-size: 18px;
      cursor: pointer;
      svg {
        margin-right: 10px;
      }
    }
  }
  .experiences {
    &-header {
      color: gray;
    }

    .experience {
      margin: 10px 0;
    }
  }
  .private {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    .label {
      margin-right: 20px;
    }

    .icon {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .ant-switch-inner {
    display: flex;
    align-items: center;
  }

  .langTag {
    width: 90%;
    margin: 3px 0;
    padding: 3px 10px;
    display: flex;
    color: white;
    background: #458ff6;
    border-radius: 20px;

    .close {
      cursor: pointer;
      margin-left: 10px;
    }
  }
}
