.auth-page {
	display: flex;
	flex-direction: column;

	.shape {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
	}

	.auth-heading {
		width: 80%;
		margin: auto;
		text-align: center;
	}

	.logo-container {
		height: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.form-container {
		padding: 50px 0;
		display: flex;
		justify-content: center;
	}
	.footer_ {
		display: flex;
		flex-direction: column;
		margin-top: 30px;
		.socials {
			display: flex;
			justify-self: unset;
			align-items: end;
			margin-top: 10px;
			flex-direction: row;
			padding: 5px;
			justify-content: center;
			.social-icon {
				width: 50px;
				height: 30px;
				margin: 0 5px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.form-card {
		position: relative;
		padding: 80px 0 20px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-shadow: 5px 11px 23px rgba(0, 0, 0, 0.25);
		border-radius: 20px;
		background: white;

		.dropdown-languages {
			position: absolute;
			left: unset;
			right: 20px;
			top: 20px;

			button {
				background: white !important;
			}
		}
	}

	.form {
		width: 70%;
		margin: auto;

		input {
			font-size: 14px;
		}

		button {
			background-color: unset;
		}

		&-errors {
			margin-bottom: 20px;
		}
		&-error {
			color: red;
		}
		&-error-icon {
			vertical-align: middle;
			margin-right: 5px;
		}
	}

	.socials-auth {
		margin-top: 20px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		&-icons {
			width: 100%;
			display: flex;
			justify-content: center;

			* {
				margin: 0 10px;
			}
		}
	}

	.nav2-links {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 30px;

		&-link {
			margin: 0 50px;
			text-align: center;
		}
	}

	.copyright {
		position: fixed;
		bottom: 20px;
		left: 20px;
		color: #458ff6;
	}

	.sweet-alert-buttons {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 50px;
	}
	.socials-auth-icon {
		cursor: pointer;
		background: none;
		border: none;
	}
}

html[dir="rtl"] {
	.auth-page {
		.form-card {
			.dropdown-languages {
				right: unset;
				left: 20px;
			}
		}
	}
}

@media screen {
	.auth-page {
		.logo-container {
			//   margin-left: 50px;
			// flex-direction: row;
			.leafunder-logo {
				margin-top: -20px;
			}
			.ofppt-logo {
				height: 80px;
			}
		}
	}
}
