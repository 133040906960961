@import url(https://themes.googleusercontent.com/fonts/css?kit=OPeqXG-QxW3ZD8BtmPikfA);
#termeOfService ul.lst-kix_ndakdmdmfs5c-0,
#termeOfService ul.lst-kix_ndakdmdmfs5c-1,
#termeOfService ul.lst-kix_ndakdmdmfs5c-2,
#termeOfService ul.lst-kix_ndakdmdmfs5c-3 {
    list-style-type: none
}

#termeOfService .lst-kix_pboflc8hokgw-1>li:before,
#termeOfService .lst-kix_pboflc8hokgw-2>li:before,
#termeOfService .lst-kix_pboflc8hokgw-3>li:before,
#termeOfService .lst-kix_pboflc8hokgw-6>li:before,
#termeOfService .lst-kix_pboflc8hokgw-7>li:before,
#termeOfService .lst-kix_pboflc8hokgw-8>li:before {
    content: "-  "
}

#termeOfService ul.lst-kix_ikhn4zuwzvqx-2,
#termeOfService ul.lst-kix_ikhn4zuwzvqx-3,
#termeOfService ul.lst-kix_ikhn4zuwzvqx-4,
#termeOfService ul.lst-kix_ikhn4zuwzvqx-5 {
    list-style-type: none
}

#termeOfService .lst-kix_5d47rtb06sy-1>li:before {
    content: "-  "
}

#termeOfService ul.lst-kix_ikhn4zuwzvqx-0,
#termeOfService ul.lst-kix_ikhn4zuwzvqx-1 {
    list-style-type: none
}

#termeOfService .lst-kix_5d47rtb06sy-0>li:before,
#termeOfService .lst-kix_5d47rtb06sy-4>li:before {
    content: "-  "
}

#termeOfService ul.lst-kix_ndakdmdmfs5c-8 {
    list-style-type: none
}

#termeOfService .lst-kix_5d47rtb06sy-3>li:before,
#termeOfService .lst-kix_pboflc8hokgw-4>li:before {
    content: "-  "
}

#termeOfService ul.lst-kix_ikhn4zuwzvqx-6,
#termeOfService ul.lst-kix_ikhn4zuwzvqx-7,
#termeOfService ul.lst-kix_ikhn4zuwzvqx-8,
#termeOfService ul.lst-kix_ndakdmdmfs5c-4,
#termeOfService ul.lst-kix_ndakdmdmfs5c-5,
#termeOfService ul.lst-kix_ndakdmdmfs5c-6,
#termeOfService ul.lst-kix_ndakdmdmfs5c-7 {
    list-style-type: none
}

#termeOfService .lst-kix_5d47rtb06sy-2>li:before,
#termeOfService .lst-kix_ikhn4zuwzvqx-7>li:before,
#termeOfService .lst-kix_ikhn4zuwzvqx-8>li:before,
#termeOfService .lst-kix_ndakdmdmfs5c-3>li:before,
#termeOfService .lst-kix_ndakdmdmfs5c-4>li:before,
#termeOfService .lst-kix_ndakdmdmfs5c-5>li:before,
#termeOfService .lst-kix_ndakdmdmfs5c-6>li:before,
#termeOfService .lst-kix_ndakdmdmfs5c-7>li:before,
#termeOfService .lst-kix_ndakdmdmfs5c-8>li:before,
#termeOfService .lst-kix_pboflc8hokgw-0>li:before,
#termeOfService .lst-kix_pboflc8hokgw-5>li:before {
    content: "-  "
}

#termeOfService ul.lst-kix_pboflc8hokgw-0,
#termeOfService ul.lst-kix_pboflc8hokgw-2 {
    list-style-type: none
}

#termeOfService .lst-kix_ndakdmdmfs5c-2>li:before {
    content: "-  "
}

#termeOfService ul.lst-kix_5d47rtb06sy-5,
#termeOfService ul.lst-kix_pboflc8hokgw-1,
#termeOfService ul.lst-kix_pboflc8hokgw-3,
#termeOfService ul.lst-kix_pboflc8hokgw-4 {
    list-style-type: none
}

#termeOfService .lst-kix_ndakdmdmfs5c-0>li:before,
#termeOfService .lst-kix_ndakdmdmfs5c-1>li:before {
    content: "-  "
}

#termeOfService ul.lst-kix_5d47rtb06sy-0,
#termeOfService ul.lst-kix_5d47rtb06sy-1,
#termeOfService ul.lst-kix_5d47rtb06sy-2,
#termeOfService ul.lst-kix_5d47rtb06sy-3,
#termeOfService ul.lst-kix_5d47rtb06sy-4,
#termeOfService ul.lst-kix_5d47rtb06sy-6,
#termeOfService ul.lst-kix_5d47rtb06sy-7,
#termeOfService ul.lst-kix_5d47rtb06sy-8 {
    list-style-type: none
}

#termeOfService .lst-kix_5d47rtb06sy-5>li:before,
#termeOfService .lst-kix_5d47rtb06sy-6>li:before,
#termeOfService .lst-kix_5d47rtb06sy-7>li:before,
#termeOfService .lst-kix_5d47rtb06sy-8>li:before,
#termeOfService .lst-kix_ikhn4zuwzvqx-0>li:before,
#termeOfService .lst-kix_ikhn4zuwzvqx-1>li:before,
#termeOfService .lst-kix_ikhn4zuwzvqx-4>li:before {
    content: "-  "
}

#termeOfService ul.lst-kix_pboflc8hokgw-5,
#termeOfService ul.lst-kix_pboflc8hokgw-6 {
    list-style-type: none
}

#termeOfService .lst-kix_ikhn4zuwzvqx-5>li:before {
    content: "-  "
}

#termeOfService ul.lst-kix_pboflc8hokgw-7,
#termeOfService ul.lst-kix_pboflc8hokgw-8 {
    list-style-type: none
}

#termeOfService .lst-kix_ikhn4zuwzvqx-2>li:before,
#termeOfService .lst-kix_ikhn4zuwzvqx-3>li:before,
#termeOfService .lst-kix_ikhn4zuwzvqx-6>li:before {
    content: "-  "
}

#termeOfService ol {
    margin: 0;
    padding: 0
}

#termeOfService table td,
#termeOfService table th {
    padding: 0
}

#termeOfService .c4 {
    margin-left: 36pt;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: -31.5pt
}

#termeOfService .c10 {
    -webkit-text-decoration-skip: none;
    color: #4a86e8;
    text-decoration: underline;
    vertical-align: baseline;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
    font-size: 13pt;
    font-family: "Arial";
    font-style: italic
}

#termeOfService .c8 {
    margin-left: -31.5pt;
    padding-top: 0;
    padding-bottom: 0;
    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: -31.5pt
}

#termeOfService .c9 {
    background-color: #f8f9fa;
    color: #202124;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 21pt;
    font-family: "Arial";
    font-style: normal
}

#termeOfService .c3 {
    margin-left: -31.5pt
}

#termeOfService .c3,
#termeOfService .c18 {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: -31.5pt
}

#termeOfService .c6 {
    color: #000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 15pt;
    font-family: "Arial";
    font-style: normal
}

#termeOfService .c14 {
    color: #202124
}

#termeOfService .c1,
#termeOfService .c14 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
}

#termeOfService .c1 {
    color: #000
}

#termeOfService .c2 {
    color: #0b5394;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: italic
}

#termeOfService .c13 {
    padding-top: 0;
    padding-bottom: 0;
    orphans: 2;
    widows: 2;
    text-align: center
}

#termeOfService .c21 {
    -webkit-text-decoration-skip: none;
    color: #4a86e8;
    text-decoration: underline;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
    font-style: italic
}

#termeOfService .c16 {
    font-weight: 400;
    font-family: "Roboto"
}

#termeOfService .c20 {
    padding: 0;
    margin: 0
}

#termeOfService .c22 {
    color: inherit;
    text-decoration: inherit
}

#termeOfService .c17 {
    padding: 72pt
}

#termeOfService .c11 {
    font-style: italic;
    color: #0b5394
}

#termeOfService .c23 {
    vertical-align: baseline;
    font-family: "Arial"
}

#termeOfService .c12 {
    text-indent: 31.5pt
}

#termeOfService .c19 {
    color: #202124
}

#termeOfService .c7 {
    font-weight: 700
}

#termeOfService .c0 {
    font-size: 12pt
}

#termeOfService .c5 {
    height: 11pt
}

#termeOfService .title {
    color: #000;
    font-size: 26pt;
    padding-bottom: 3pt
}

#termeOfService .subtitle,
#termeOfService .title {
    padding-top: 0;
    font-family: "Arial";
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#termeOfService .subtitle {
    color: #666;
    font-size: 15pt;
    padding-bottom: 16pt
}

#termeOfService li,
#termeOfService p {
    color: #000;
    font-size: 11pt;
    font-family: "Arial"
}

#termeOfService p {
    margin: 0
}

#termeOfService h1 {
    padding-top: 20pt;
    font-size: 20pt
}

#termeOfService h1,
#termeOfService h2 {
    color: #000;
    padding-bottom: 6pt;
    font-family: "Arial";
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#termeOfService h2 {
    padding-top: 18pt;
    font-size: 16pt
}

#termeOfService h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#termeOfService h4 {
    padding-top: 14pt;
    font-size: 12pt
}

#termeOfService h4,
#termeOfService h5 {
    color: #666;
    padding-bottom: 4pt;
    font-family: "Arial";
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#termeOfService h5,
#termeOfService h6 {
    padding-top: 12pt;
    font-size: 11pt
}

#termeOfService h6 {
    color: #666;
    padding-bottom: 4pt;
    font-family: "Arial";
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}