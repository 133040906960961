@import "../global";
@import "../variables";

$filler-height: 150px;
$svg-height: 80px;

.profile-card {
  background-color: white;
  padding: 20px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  width: 100%;
  min-height: $filler-height + $svg-height + 150px;

  &-filler {
    height: $filler-height;
    width: 100%;
    background-color: #436ede;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px 10px 0 0;

    &-shape {
      position: absolute;
      top: $filler-height;
      left: 0;
      fill: #436ede;
      width: 100%;
      height: auto;
    }
  }

  .user-name-follow {
    z-index: 1;
    color: white;
    position: relative;
    display: flex;
    justify-content: space-between;

    .name {
      text-transform: uppercase;
    }
  }

  .user-location {
    position: relative;
    z-index: 1;
    color: white;
    font-size: 10px;
    width: 100%;
    text-align: center;
    margin: 10px 0;

    svg {
      margin: 0 5px;
    }
  }

  .user-avatar-send {
    position: absolute;
    z-index: 1;
    top: $filler-height;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;

    .avatar {
      width: $svg-height + ($svg-height / 4);
      height: $svg-height + ($svg-height / 4);
      border-radius: $svg-height;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-top: -20px;
    }

    .avatar-side {
      position: relative;
    }

    .account-type {
      position: absolute;
      top: $svg-height - 20px;
      left: $svg-height + ($svg-height / 4) - ($svg-height + ($svg-height / 4)) /
        2;
      font-size: 10px;
      font-weight: 700;
      color: white;
      padding: 5px 10px;
      border-radius: 8px;
    }

    .send {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border: 1px solid;
    }
  }

  .user-bio {
    position: absolute;
    height: 100px;
    left: 0;
    top: $filler-height + $svg-height + $svg-height / 4 + 10px;
    font-size: 13px;
    width: 100%;
    padding: 0 20px;
    overflow: hidden;
  }

  .user-bio-gradient {
    height: 80px;
    width: 100%;
    position: absolute;
    bottom: 0;
    border-radius: 0 0 10px 10px;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      white,
      white
    );
  }

  .see-more {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
  }
}
