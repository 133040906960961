.add-idea-page {
	margin-top: 50px;

	.editor {
		border: 1px solid;
	}

	.editor-control-bar {
		padding: 10px;
		button {
			padding: 0 !important;
		}
	}

	.navigation-buttons {
		display: flex;
		justify-content: space-between;
	}
	.first-navigation-buttons {
		display: flex;
		justify-content: flex-end;
	}

	.center-buttons {
		display: flex;
		justify-content: center;
	}
	.uppy-Root {
		border-radius: 10px;
		border-color: #458ff6 ;

		&.uppy-DragDrop-container:focus {
			box-shadow: none;
		}

		.uppy-DragDrop-arrow {
			fill: #458ff6 ;
		}

		.uppy-DragDrop-label {
			color: grey;
		}

		.uppy-DragDrop-browse {
			color: #458ff6 ;
		}
	}
	.imgslist {
		margin-top: 2%;
		.card {
			height: 100%;
			.imgCard {
				height: 100px;
				object-fit: cover;
			}
		}
		.cardbody {
			.img-btn-Delete {
				position: absolute !important;

				left: 0;
				bottom: 0;
				width: 100% !important;
				border-radius: 0;
			}
		}
	}
	.upload-img {
		width: 250px;
		height: 250px;
		background-size: cover;
		object-fit: cover;
	}

	.upload-img img {
		visibility: hidden;
	}

	.img-btn-upload {
		position: absolute !important;
		left: 0;
		bottom: 0;
		width: 100% !important;
		border-radius: 0;
	}

	// .dark-layout {
	//   .uppy-Root {
	//     // background-color: $theme-dark-body-bg;
	//   }
	// }
	.uppy-Dashboard-AddFiles-info,
	.uppy-StatusBar {
		display: none !important;
	}

	.langTag {
		width: 100%;
		margin: 2px 0;
		padding: 5px 10px;
		font-size: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: white;
		background: #458ff6;
		border-radius: 20px;

		.close {
			cursor: pointer;
			width: 15px;
			margin-left: 5px;
		}
	}
}
