.Delete {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200px;
  background-color: #fbfbfb;
  justify-content: center;
  align-items: center;
  // padding-top: 9%;
  h6 {
    color: red;
  }
  p {
    font-size: 15px;
  }
  .select {
    width: 60%;
    margin-top: 4%;
  }
  .Buttons {
    display: flex;
    .cancelButton {
      width: 120px;
      height: 35px;
      border-radius: 10px;
      background-color: #109cf1;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      padding: 3%;
      margin: 6%;
      cursor: pointer;
    }
    .deleteButton {
      width: 120px;
      height: 35px;
      border-radius: 10px;
      border: 1px solid red;
      color: red;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      padding: 3%;
      margin: 6%;
      cursor: pointer;
    }
  }
}
