@import "../global";
@import "../variables";

.notif-page {
  margin-top: 150px;

  .carta {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 60vh;
  }

  .notifs-wrapper {
    height: 100%;
    width: 100%;
    border-top: 2px solid $primary;
  }

  .mark-all-as-read {
    padding: 10px;
    font-size: 15px;
    cursor: pointer;
    color: $primary;

    &:hover {
      color: $primaryHover;
    }
  }
}
