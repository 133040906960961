.Edit {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 200px;
	background-color: #fbfbfb;
	justify-content: center;
	align-items: center;
	padding: 20px;
	transition: all 0.2s ease-in-out;
	// padding-top: 9%;
	.select {
		width: 60%;
		margin: 10px 0;
		.ant-select {
			border-color: red;
		}
	}
	.saveButton {
		width: 120px;
		height: 35px;
		border-radius: 10px;
		background-color: #109cf1;
		color: #ffffff;
		font-size: 12px;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		margin-top: 5px;
	}

	.x {
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
	}
}
