.card {
  // margin: 10px !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border: 0;
  border-radius: 20px !important;
  align-items: flex-start;
  background: #ffff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1) !important;
  transition: transform 0.2s ease;
  &:hover {
    transform: translateY(-10px);
  }
  .infos {
    padding: 10px;
    width: 100%;
    margin: 0;
    .user {
      display: flex;
      padding: 0;
      justify-content: center;
      align-items: center;
      .Ellipse_224 {
        border: 2px solid #458ff6;
        border-radius: 50%;
        background-image: url("https://images.unsplash.com/photo-1554080353-a576cf803bda?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1534&q=80");
        background-size: cover;
        width: 32px;
        min-width: 32px;
        height: 32px;
        min-height: 32px;
        margin: 0 5px;
      }
      .user-infos {
        .name {
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          color: #000000;
          text-transform: capitalize;
        }
        .position {
          font-weight: 300;
          font-size: 12px;
          line-height: 18px;
          color: #585555;
        }
      }
    }
    .socialidea {
      display: flex;
      justify-content: center;
      align-items: center;
      .socialideaimg {
        border: 2px solid #458ff6;
        border-radius: 50%;
        background-image: url("https://images.unsplash.com/photo-1532007271951-c487760934ae?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80");
        background-size: cover;
        width: 32px;
        min-width: 32px;
        height: 32px;
        min-height: 32px;
        margin: 0 5px;
      }
      .ideas-infos {
        .name {
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          color: #000000;
        }
        .position {
          font-weight: 300;
          font-size: 12px;
          line-height: 18px;
          color: #585555;
        }
      }
    }
  }
  .ideaTitle {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #458ff6;
    margin: 10px 0;
    padding: 0 20px;
  }
  .ideadesc {
    width: 100%;
    height: 100px;
    font-weight: 500;
    font-size: 13px;
    color: #5a5a5a;
    overflow: auto;
    padding: 0 20px;
  }
  .ideadesc::-webkit-scrollbar {
    display: none;
  }
  .ideaaction {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    justify-content: space-between;
    .ideadatesection {
      // width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .ideadate {
        width: 100%;
        height: 14px;
        font-weight: 300;
        font-size: 10px;
        text-align: center;
        color: #585555;
      }
    }
    .more {
      align-self: flex-end;
      height: 30px;
      background-color: #f1f9ff;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      transition: background-color 0.2s ease-in-out;
      .seemore {
        text-align: center;
        width: 62px;
        height: 20px;
        font-weight: 500;
        font-size: 10px;
        display: flex;
        align-items: center;
        color: #458ff6;
      }
    }
    .more:hover {
      background-color: #daeffe;
    }
  }
}

.thumb {
  width: 100%;
  height: 174px;
  // background-size: cover;
  // border-radius: 5px 5px 0 0;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0 0;
    object-fit: cover;
  }
  .state {
    position: absolute;
    width: 100px;
    height: 33px;
    background-color: #ffff;
    right: -13px;
    top: 30px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    color: #000000;
    border-radius: 20px 0px 5px 20px;
    text-transform: uppercase;
  }
  .state:after {
    position: absolute;
    content: "";
    top: -8px;
    right: 0;
    left: unset;
    border-top: 8px solid transparent;
    border-left: 13px solid #b6b6b6;
    border-right: unset;
  }
}
html[dir="rtl"] {
  .state {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100px;
    height: 33px;
    background-color: #ffff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    padding-left: unset;
    padding-right: 16px;
    top: 25px;
    right: 185px;
    left: unset;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }
  .state:after {
    position: absolute;
    content: "";
    top: -8px;
    right: unset;
    left: 0;
    border-top: 8px solid transparent;
    border-right: 13px solid #b6b6b6;
    border-left: unset;
  }
}
@media (max-width: 320px) {
  .card {
    width: 300px;
    .state {
      left: 110px;
    }
  }
}

// .bo93a {
//   height: 100%;
//   // padding: 20px;
//   background: #ffffff;
//   box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
//   border-radius: 20px;
//   position: relative;
// }
// .dis-card {
//   margin: 20px 0;

//   svg {
//     vertical-align: unset;
//   }
// }
// .dis-card img {
//   width: 272px;
//   height: 174px;
//   border-radius: 20px 20px 0 0;
// }

// .card .infos .title {
//   position: relative;
//   margin: 10px 0;
//   letter-spacing: 3px;
//   color: #152536;
//   font-family: "Grotesque Black", sans-serif;
//   font-size: 1rem;
//   text-transform: uppercase;
//   text-shadow: 0 0 0px #32577f;
// }
// .card .infos .flag {
//   position: absolute;
//   top: 50%;
//   right: 0;
//   transform: translateY(-50%);
//   width: 35px;
//   height: 23px;
//   background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/397014/flag.png") no-repeat top right;
//   background-size: 100% auto;
//   display: inline-block;
// }
// .card .infos .date, .card .infos .seats {
//   margin-bottom: 10px;
//   text-transform: uppercase;
//   font-size: 0.85rem;
//   color: rgba(21, 37, 54, 0.7);
//   font-family: "Grotesque", sans-serif;
// }
// .card .infos .seats {
//   display: inline-block;
//   margin-bottom: 24px;
//   padding-bottom: 24px;
//   border-bottom: 1px solid rgba(0, 0, 0, 0.2);
//   opacity: 0;
//   transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
// }
// .card .infos .txt {
//   font-family: "Merriweather", sans-serif;
//   line-height: 2;
//   font-size: 0.95rem;
//   color: rgba(21, 37, 54, 0.7);
//   opacity: 0;
//   transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
// }
// .card .infos .details {
//   position: absolute;
//   left: 0;
//   left: 0;
//   bottom: 0;
//   margin: 10px 0;
//   padding: 20px 24px;
//   letter-spacing: 1px;
//   color: #4e958b;
//   font-family: "Grotesque Black", sans-serif;
//   font-size: 0.9rem;
//   text-transform: uppercase;
//   cursor: pointer;
//   opacity: 0;
//   transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
// }
// .card:hover .infos {
//   transform: translateY(-260px);
// }
// .card:hover .infos .seats, .card:hover .infos .txt, .card:hover .infos .details {
//   opacity: 1;
// }
