.add-idea-page {
  margin-top: 50px;

  .carta {
    padding: 20px;
    margin: 20px 0;

    svg {
      vertical-align: unset;
    }
  }

  .editor {
    border: 1px solid;
  }

  .editor-control-bar {
    padding: 10px;
    button {
      padding: 0 !important;
    }
  }

  .navigation-buttons {
    display: flex;
    justify-content: space-between;
  }
  .first-navigation-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .center-buttons{
    display: flex;
    justify-content: center;
  }
  .uppy-Dashboard-AddFiles-info, .uppy-StatusBar {
    display: none !important;
  }

  .langTag {
    width: 100%;
    margin: 2px 0;
    padding: 5px 10px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    background: #458ff6;
    border-radius: 20px;

    .close {
      cursor: pointer;
      width: 15px;
      margin-left: 5px;
    }
  }
}
