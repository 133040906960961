@import "../global";
@import "../variables";

.chat-app {
	margin: 50px 0;

	.carta {
		padding: 20px;
		overflow: hidden;
	}

	.ant-badge {
		display: flex !important;
		line-height: unset !important;
		.nav-button {
			width: 100%;
			// width: ;
		}
	}

	.nav-button {
		// background: red;
		border: 1px solid $primary !important;
		padding: 10px !important;
		text-align: center !important;
		border-radius: 10px !important;
		transition: all 0.2s ease-in-out !important;
		cursor: pointer !important;

		&:hover,
		&-selected {
			background: $primary !important;
			color: white !important;
		}
	}

	.go-back {
		cursor: pointer;
		transition: all 0.2s ease-in-out;

		&:hover {
			color: $primary;
		}
	}

	.convs,
	.conversation,
	.recipient-profile {
		height: 60vh;
	}
	.fixed {
		&--zoom {
			display: flex;
			height: 100px;
			background-color: #cae2fc;
			align-items: center;
			justify-content: center;
			font-size: 15px;
			cursor: pointer;
		}
	}
	.convs {
		// height: 800px;
		padding: 0;
		padding-top: 20px;
		display: flex;
		flex-direction: column;

		&-profile {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 10px;
			.ant-avatar {
				min-width: 50px;
				min-height: 50px;
				max-width: 50px;
				max-height: 50px;
			}

			.name {
				text-transform: capitalize;
			}
		}

		&-search {
			padding: 0 10px;
			.ant-input-affix-wrapper {
				border-radius: 10px;
			}
		}

		&-convs {
			border-top: 1px solid #9999995c;
			overflow-y: scroll;
			height: 100%;
		}
	}

	.conversation {
		padding: 0;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		.ant-avatar {
			min-width: 20px !important;
			min-height: 20px !important;
			max-width: 20px !important;
			max-height: 20px !important;
		}
		.convo-back {
			cursor: pointer;
			transition: all 0.2s ease-in-out;

			&:hover {
				color: $primary;
			}
		}

		.recipient-header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px;
			background: #fafbff;
			border-bottom: 1px solid #9999995c;

			.profile {
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				.ant-avatar {
					min-width: 30px !important;
					min-height: 30px !important;
					max-width: 30px !important;
					max-height: 30px !important;
				}
			}

			.fullname-status {
				.fullname {
					font-weight: 700;
					font-size: 13px;
					text-transform: capitalize;
				}

				.state {
					margin-top: 5px;
					font-weight: 300;
					font-size: 10px;
				}

				.online {
					color: $primary;
					line-height: 12px;
				}
			}

			.buttons {
				display: flex;
				align-items: center;
				justify-content: center;
				.botona {
					box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
					margin: 0 5px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 30px;
					background: white;
					padding: 10px;
				}
			}
		}

		.conv-body {
			height: 100%;
			// background: red;
			overflow-y: scroll;
			text-align: center;
		}

		.conv-input {
			padding: 10px 20px;
			display: flex;
			.potona {
				width: 40px !important;
				height: 40px !important;
				padding: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid white;
				border-radius: 20px;
				cursor: pointer;
				background-color: $primary;
				transition: all 0.2s ease-in-out;
			}

			.message-input {
				width: 100%;

				.ant-input {
					border-radius: 20px;
				}
			}

			.insert-button {
				position: relative;
				color: white;
				background: $primary;
				margin: 0 5px;
			}

			.file-button {
				position: absolute;
				color: white;
				left: 0;

				&-closed {
					transform: rotate(-180deg);
					bottom: 0px;
					z-index: -1;
				}

				&-open {
					transform: rotate(0deg);
					bottom: 45px;
					z-index: 0;
				}
			}
			.image-button {
				position: absolute;
				color: white;
				left: 0;

				&-closed {
					transform: rotate(-180deg);
					bottom: 0px;
					z-index: -1;
				}

				&-open {
					transform: rotate(0deg);
					bottom: 45px * 2;
					z-index: 0;
				}
			}
			.task-button {
				position: absolute;
				left: 0;

				&-closed {
					transform: rotate(-180deg);
					bottom: 0px;
					z-index: -1;
				}

				&-open {
					transform: rotate(0deg);
					bottom: 45px * 3;
					z-index: 0;
				}
			}

			.emoji-button {
				border: 1px solid #9999995c;
				background-color: white;
				color: #999999;
				margin: 0 5px;
			}

			.send-button {
				background: $primary;
				color: white;
			}
		}
	}
	.no-recipient {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: #999999;

		.title {
			font-weight: 700;
			font-size: 20px;
		}
	}

	.recipient-profile {
		display: flex;
		flex-direction: column;
		// justify-content: center;
		align-items: center;

		&__go-back {
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			align-self: flex-start;

			&:hover {
				color: $primary;
			}
		}

		.avatar {
			width: 100px;
			height: 100px;
			border-radius: 100px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		.name-position {
			text-align: center;

			.name {
				margin-top: 10px;
				font-weight: 700;
				font-size: 15px;
			}

			.position {
				font-weight: 300;
				font-size: 13px;
				color: #999999;
			}
		}

		.search-zoom {
			margin-top: 40px;
			display: flex;
			justify-content: space-evenly;
			word-break: break-all;

			.buttona {
				margin: 0 10px;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;

				.icon {
					display: flex;
					justify-content: center;
					align-items: center;
					background: #cae2fc;
					color: $primary;
					width: 50px;
					height: 50px;
					border-radius: 80px;
				}

				.text {
					text-align: center;
					font-size: 13px;
					font-weight: 300;
				}
			}
		}
		.search_input {
			margin-top: 15px;
			width: 100%;
			height: 45px;

			.ant-input {
				border-radius: 20px;
			}
		}

		.docs-links {
			width: 100%;
			margin-top: 40px;
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			.ptn {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 50px;
				text-align: center;
				background: #cae2fc;
				padding: 10px;
				margin: 0 5px;
				border-radius: 10px;
				font-size: 12px;
				cursor: pointer;
			}
		}
	}
	.ant-avatar-circle {
		justify-content: center;
		align-items: center;
		display: flex;
	}
}

html[dir="rtl"] {
	.chat-app {
		.send-button {
			transform: rotateY(180deg);
		}
	}
}
