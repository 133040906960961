@import "../global";
@import "../variables";

.book-page {
  padding-top: 50px;

  .carta {
    padding: 20px;
    margin-bottom: 20px;
    height: unset;
  }

  .title {
    font-weight: 700;
    color: $primary;

    .name {
      text-transform: uppercase;
      color: black;
    }
  }

  .cell {
    font-weight: 700;
    margin: 0 5px;

    &-selected {
      background: $primary;
      color: white;
      border-radius: 50px;
    }

    &-stroke {
      border: 2px solid $primary;
      border-radius: 50px;
    }
  }

  .calendar-wrapper {
    border: 2px solid $primary;
    border-radius: 5px;
    padding: 20px;
    padding-top: 70px;
    position: relative;

    .picture {
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 50px;
      border: 2px solid $primary;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .booker-wrapper {
    border: 1px solid #999999;
    border-radius: 5px;
    padding: 10px;
    padding-top: 30px;

    .slots-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .slot {
        width: 100%;
        padding: 5px;
        margin: 5px 0;
        text-align: center;
        background: #f1f9ff;
        border: 1px solid $primary;
        border-radius: 5px;
        box-sizing: border-box;
        // box-shadow: 0px 3px 6px 2px rgba(53, 173, 186, 0.5);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
      }

      .slot:hover {
        background: $primary;
        color: white;
      }

      .no-slot {
        padding: 30px 10px;
        color: #999999;
        width: 100%;
        text-align: center;
      }
    }
    .slot2 {
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        width: 100%;
        font-weight: 700;
        text-align: center;
        padding: 5px;
        margin: 5px 0;
        text-align: center;
        background: #f1f9ff;
        border: 1px solid $primary;
        border-radius: 5px;
        box-sizing: border-box;
      }
    }
    .add-slot {
      // background-color: red;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }

  .header {
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .ant-picker-calendar-header {
    justify-content: center;
  }

  .ant-select-selector {
    border-radius: 10px !important;
  }

  .ant-radio-button-wrapper:nth-child(1) {
    border-radius: 10px 0 0 10px !important;
  }
  .ant-radio-button-wrapper:nth-child(2) {
    border-radius: 0 10px 10px 0 !important;
  }
  .ant-picker {
    width: 100% !important;
    border-radius: 10px !important;
  }
}

.ant-select-selector {
  border-radius: 10px !important;
}

.modal-lead {
  font-weight: 700;
  text-align: center;
  font-size: 15px;
  margin-bottom: 20px;
}

.instructions {
  font-size: 12px;
  font-weight: 300;
  color: #999999;
  text-align: center;
}
