.AllIdeas {
  width: 100%;
  position: relative;
  padding-top: 100px;
  padding-top: 50px;
  .carta {
    padding: 20px;
    display: flex;
    justify-content: space-around;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1) !important;
  }

  .search {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-input {
    width: 100%;
    border-radius: 10px;
  }
  .ant-btn {
    border-radius: 10px;
    margin: 0 10px;
  }
  .ideaGrid {
    position: relative;
    padding-bottom: 100px;
    .grid-item {
      padding: 5px;
      text-align: center;
    }
    .Cards {
      //   width: 100%;
      //   display: flex;
      //   flex-direction: row;
      //   align-items: flex-start;
      //   justify-content: space-between
      text-align: center;
      padding: 20px 0;
      font-size: 30px;
      border-radius: 10px;
    }

    .pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .Filter {
    width: 100%;
    height: 600px;
    background-color: white;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
    //   position: fixed;
  }
  .bolisi {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 340px) {
  .ideaGrid {
    grid-template-columns: repeat(2, 1fr);
    .Filter {
      background-color: white;
      padding: 10px;
      .bo3o {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
      }
    }
  }
}
