.notVerified-page {
  .notVerif-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .nv {
    &-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      margin-top: 10px;
      margin-bottom: 10px;

      &-container {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      }

      &-nav {
        margin: 0 10px;
      }
    }

    &-card {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      width: 100%;
      padding: 20px 50px 80px 50px;
    }
    &-lead {
      width: 80%;
      text-align: center;
      margin: auto;
    }

    &-user-dropdown {
      display: inline-block;
      cursor: pointer;
      &-name {
        margin: 0 5px;
      }
    }
  }
}
