@import "../global";
@import "../variables";

.sessions-page {
  padding-top: 100px;

  .filter {
    height: auto;
  }
  .bola {
    height: 60vh;
    overflow: auto;
    display: flex;
    justify-content: center;
  }
  .col-md-2,
  .col-md-5 {
    border-right: 1px solid rgb(219, 219, 219);
  }
  .session-card {
    border-left: 10px solid;
    padding: 20px;
    margin: 0 10px;
    margin-bottom: 20px;

    &-title {
      justify-content: center;
      margin-bottom: 40px;
      font-size: 12px;
      font-weight: 700;
    }

    &-waiting {
      border-color: #ffc000;

      .status-status {
        background-color: #ffc000 !important;
        color: black;
      }

      .go-to-room {
        color: rgb(198, 198, 198);
        cursor: not-allowed;
      }
    }
    &-confirmed {
      border-color: #28a745;

      .status-status {
        background-color: #28a745 !important;
      }
      .go-to-room {
        color: $primary;
        cursor: pointer;
      }
    }
    &-canceled {
      border-color: #eb4050;

      .status-status {
        background-color: #eb4050 !important;
      }
      .go-to-room {
        color: rgb(198, 198, 198);
        cursor: not-allowed;
      }
    }
    &-finished {
      border-color: #999999;

      .status-status {
        background-color: #999999 !important;
      }
      .go-to-room {
        color: $primary;
        cursor: pointer;
      }
    }
    .session-item {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .heading {
        color: $primary;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 10px;
        margin-bottom: 5px;
        text-align: center;
      }
    }

    .about-idea {
      &-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .idea-image {
          border-radius: 5px;
          height: 100px;
          //   min-width: 90px;
          width: 100%;
          margin: 0 5px;
        }

        .idea-title-desc {
          padding: 0 10px;
          margin: 0 5px;
          .title {
            font-weight: 700;
            font-size: 15px;
            line-height: 12px;
            margin-bottom: 10px;
          }
          .desc {
            color: black;
            font-size: 12px !important;
          }

          .see-more {
            font-size: 10px;
            color: $primary;
            cursor: pointer;
          }
        }
      }
    }

    .idea-holder {
      .team-leader {
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-avatar {
          min-width: 30px !important;
          min-height: 30px !important;
          max-width: 30px !important;
          max-height: 30px !important;
        }

        .infos {
          margin: 0 5px;
          .name {
            text-align: center;
            font-weight: 700;
            font-size: 13px;
          }
          .post {
            text-align: center;
            font-weight: 300;
            font-size: 10px;
          }
        }
      }

      .team-members {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .mentor {
      &-infos {
        width: 100%;
        text-align: center;
        .name {
          font-weight: 700;
          font-size: 12px;
        }
        .exp {
          font-weight: 300;
          font-size: 12px;
        }
      }
    }

    .status {
      background: unset !important;
      box-shadow: unset !important;
      overflow: unset !important;
      border-radius: unset !important;
      text-overflow: unset !important;
      white-space: unset !important;
      color: unset !important;
      float: unset !important;
      padding: unset !important;
      
      &-status {
        // width: 80%;
        text-align: center;
        padding: 5px 10px;
        color: white;
        border-radius: 10px;
        text-transform: capitalize;
        font-size: 12px;
        margin-bottom: 20px;
      }

      .cancel {
        // margin-top: 20px;
        display: flex;
        font-size: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: red !important;
        cursor: pointer;
      }

      .accept {
        // margin-top: 20px;
        display: flex;
        font-size: 10px;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &-button {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: #28a745;
          cursor: pointer;
        }
      }

      .confirmed-text {
        font-size: 12px;
        text-align: center;
        width: 80%;
      }
      .feedback {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: $primary;
        font-size: 10px;

        &-btn {
          font-size: 10px;
          padding: 5px 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .session-room {
      .go-to-room {
        // height: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        font-size: 10px;
      }
    }
  }
}

.feedback-question {
  // background: red;
  font-weight: 700;
  text-align: center;
  margin-bottom: 50px;
}

.feedback-thankyou {
  font-weight: 700;
  color: #999999;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .sessions-page {
    // 	.bola {
    //   padding: 3%;
    // 		margin-left: -13% !important;
    // 	}
    // .filter {
    //   margin-left: -10%;
    //   margin-top: 13%;
    // }
  }
}
