.processinfo {
	width: 100%;
	padding-top: 100px;
}

.process_info {
	.process_header {
		width: 100%;
		height: 332px;
		background-image: url("https://images.unsplash.com/photo-1586892477838-2b96e85e0f96?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2082&q=80");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;
		svg {
			position: absolute;
			bottom: -1%;
			width: 100%;
		}

		.dwara {
			position: absolute;
			z-index: 8;
			bottom: -1px;
			border-radius: 20px;
			background-color: white;
			min-height: 80px;
			width: 50vw;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			justify-content: center;
			align-items: center;
	
		}
	}
	.process_content {
		margin-top: 60px;
		.process_image {
			width: 100%;
			height: 332px;
			background-image: url("https://images.unsplash.com/photo-1604003295414-8f3718ca9c65?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2200&q=80");
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			border-radius: 10px;
		}
		.process_infoII {
			width: 100%;
			padding: 15px;
			border-radius: 10px;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			font-weight: 500px;
			font-size: 20px;
		}
	}
	.stages_info {
		margin-top: 50px;
		width: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		.cardi {
			width: 100%;
			position: relative;
			cursor: pointer;
		}

		.cardi .face {
			width: 300px;
			height: 200px;
			transition: 0.5s;
		}

		.cardi .face.face1 {
			width: 100%;
			position: relative;
			background: #dca86c;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 1;
			transform: translateY(100px);
		}

		.cardi:hover .face.face1 {
			background: #40a9f3;
			transform: translateY(0);
		}

		.cardi .face.face1 .content {
			opacity: 0.5;
			transition: 0.5s;
		}

		.cardi:hover .face.face1 .content {
			opacity: 1;
		}

		.cardi .face.face1 .content img {
			max-width: 100px;
		}

		.cardi .face.face1 .content h3 {
			margin: 10px 0 0;
			padding: 0;
			color: #fff;
			text-align: center;
			font-size: 1.5em;
		}

		.cardi .face.face2 {
			width: 100%;
			position: relative;
			background: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 20px;
			box-sizing: border-box;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
			transform: translateY(-100px);
		}

		.cardi:hover .face.face2 {
			transform: translateY(0);
		}

		.cardi .face.face2 .content p {
			margin: 0;
			padding: 0;
		}

		.cardi .face.face2 .content a {
			margin: 15px 0 0;
			display: inline-block;
			text-decoration: none;
			font-weight: 900;
			color: #333;
			padding: 5px;
			border: 1px solid #333;
		}

		.cardi .face.face2 .content a:hover {
			background: #333;
			color: #fff;
		}
	}
}
