.help_menu {
	width: 430px;
	height: 400px;
	margin: 10px;
	padding: 10px;
	padding-left: 44px;
	.item-menu {
		text-align: center;
		background-color: #fbfbfb;
		margin: 10px;
		cursor: pointer;
		width: 50px;
		height: 100px;
		border: 1px grey;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		border-radius: 5px;
	}
}

html[dir="rtl"] {
	.help_menu {
		padding-left: unset;
		padding-right: 44px;
	}
}
