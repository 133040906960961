@import "../global";
@import "../variables";

.incubator-stage {
	margin: auto;
	text-align: center;
	position: relative;

	.stage {
		&-name {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: $primary;
			font-weight: 700;
			text-transform: uppercase;
			z-index: 20;
		}

		&-cercle {
			width: 20px;
			height: 20px;
			border-radius: 20px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.incubator-steps {
	width: 100%;
	padding: 0 30px;
	padding-bottom: 100px;
	margin-top: 50px;

	.coll {
		height: 98px !important;

		&-xs {
			.step {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-70%, -50%);
				z-index: 2;
			}
		}
	}

	.steps-line {
		width: 100%;
		height: 11px;
		background: $primary;
		margin-top: 10px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		&-half {
			width: 50% !important;

			&-right {
				left: -1px;
				transform: translateY(-50%);
			}
		}

		&-vertical {
			height: 200%;
			width: 11px;
			// left: 4px;
			// transform: translateY(-50%);
		}

		&-mod-1-left {
			width: 50%;
			right: 0;
			transform: translate(0, -50%);
		}
		&-mod-1-right {
			width: 50%;
			left: 0;
			transform: translate(0, -50%);
		}
	}

	.steps-left {
		left: 0;
		top: 50%;
		margin-top: 4px;
		height: 160px;
		z-index: 1;
	}

	.steps-right {
		right: 0;
		top: 50%;
		margin-top: 4px;
		height: 160px;
		z-index: 1;
	}

	.steps-dashed-line {
		// right: 50%;
		top: 50%;
		left: -50%;
		margin-top: 3px;

		&-reversed {
			left: unset;
			right: -50%;
			transform: rotateY(180deg);
		}

		&-md {
			transform: rotateY(180deg);
			right: unset;
			left: 40%;

			&-reversed {
				transform: rotateY(0deg);
				left: unset;
				right: 40%;
			}
		}
	}

	.step {
		// background: red;
		.form-control {
			height: 60px !important;
			font-size: 20px;
			padding-left: 20px !important;
			width: 90% !important;
			margin: auto;
			border-radius: 10px;
		}
		.example {
			margin: 20px 0;
			margin-bottom: 20px;
			padding: 30px 50px;
			text-align: center;
			background: rgba(0, 0, 0, 0.05);
			border-radius: 4px;
		}
		&-finished {
			.step-title {
				color: #28a745;
			}

			.step-cercle {
				background: #28a745;
				color: white;
			}
		}

		&-unlocked {
			cursor: pointer;
			.step-title {
				color: $primary;
			}

			.step-cercle {
				background: $primary;
			}
		}

		&-title {
			font-weight: 700;
			font-size: 15px;
			text-align: center;
			text-transform: uppercase;
			color: #999999;
			position: relative;
			z-index: 2;
			margin: 0 5px;
		}

		&-cercle {
			min-width: 80px !important;
			min-height: 80px !important;
			border-radius: 50px;
			background: #999999;
			border: 2px solid white;
			color: white;
			// box-shadow: ;
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
			position: relative;
			z-index: 2;
		}
	}
}

.open-modal {
	.ant-result-title {
		margin-bottom: -70px !important;
	}
	.ant-result-icon .ant-result-image{
		width: 100px !important;
	}
}
.step-modal {
	.step-description {
		margin-top: 20px;
		.title {
			margin: 10px 0;
			font-weight: 700;
			font-size: 15px;
		}
		.desc {
			color: black;
		}
		.buttons {
			margin: 20px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		hr {
			width: 100% !important;
			height: 1px !important;
			background: #999999;
			margin: 20px 0 !important;
		}

		.step-completion {
			.title {
				font-weight: 700;
				font-size: 20px;
				color: $primary;
				text-align: center;
			}
			.editorHeight {
				height: 300px;
			}
		}

		.file-entry {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 10px 0;

			.icon {
				margin: 0 10px;
				color: #999999;
			}
			.name {
				width: 100%;
			}
		}

		.add-button {
			margin-top: 5px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.learning-materials,
	.template {
		margin-top: 20px;

		.material {
			display: flex;
			justify-content: space-around;
			align-items: center;
			border: 1px solid #999999;
			padding: 10px;

			.icon {
				margin: 0 10px;
				color: #999999;
			}

			.title {
				width: 100%;
			}
		}
	}

	.ant-radio-button-wrapper:nth-child(1) {
		border-radius: 20px 0 0 20px;
	}
	.ant-radio-button-wrapper:nth-last-child(1) {
		border-radius: 0 20px 20px 0;
	}
}

html[dir="rtl"] {
	.steps-dashed-line {
		transform: rotateY(180deg);
		right: unset;
		left: 50%;

		&-reversed {
			transform: unset;
			right: 50%;
			left: unset;
		}

		&-md {
			transform: unset;
			right: 40%;
			left: unset;

			&-reversed {
				left: 40%;
				right: unset;

				transform: rotateY(180deg);
			}
		}
	}
}
