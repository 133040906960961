@import "../variables";
@import "../global";

.Ideainfo {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  .bolisi {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate((-50%, -50%));
  }
  .infosHeader {
    margin-top: 30px;

    .imagesdiv {
      .image {
        width: 100%;
        height: 350px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;
      }
    }

    .ideainfos {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        margin: 20px 0;
        h3 {
          color: #458ff6;
          font-weight: 700;
        }
      }
      .desc {
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 50px;
      }
    }
  }
  .ideabody {
    position: relative;
    background: white;
    width: 100%;
    padding: 0 20px;
    padding-top: 200px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 100px;

    .ideaState {
      position: absolute;
      width: auto;
      height: 150px;
      background-color: white;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25) !important;
      left: 50%;
      top: -60px;
      transform: translateX(-50%);
      display: flex;
      flex-direction: row;
      align-content: center;
      border-radius: 10px;
      justify-content: center;
      padding: 0;
      .line-separater {
        width: 1px;
        height: 70%;
        margin: 0;
        margin-top: 1.5rem;
        background-color: rgba(128, 128, 128, 0.5);
      }
      .state-info {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin: 0 50px;
        svg {
          width: 50px;
        }
        // border: 0.5px solid grey;
      }
      .statetrue {
        width: 180px;
        height: 112%;
        margin-top: -1%;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25) !important;
        // border: 0.5px solid grey;
      }
    }

    .DescCard {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      .youtube {
        height: 100%;
      }
      .desc {
        padding: 20px;
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.65);
        // text-align: left;
      }
      .teamSwiper {
        width: 650px;
        .swiper-end {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
    .carta {
      width: 100%;
      background: white !important;
      margin-bottom: 20px !important;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25) !important;
      border-radius: 10px !important;
      padding: 10px !important;
      display: flex !important;
      flex-direction: column;

      &-title {
        display: flex;
        margin-bottom: 10px;
        padding: 10px;
        padding-bottom: 0;
        text-align: center;
        font-size: 0.9em;
      }

      &-icon {
        margin-right: 10px;
        vertical-align: center;
      }

      .badge {
        margin: 5px;
        padding: 10px 20px;
        text-transform: uppercase;
      }

      h3 {
        font-size: 0.9rem;
        margin-bottom: 0;
        align-self: center;
      }

      .row {
        .col {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .ideaTags {
      width: 100%;
      border-radius: 10px;

      .one-value {
        flex-direction: unset;
        justify-content: space-between;

        .carta-badges {
          padding: 0 10px;
        }

        h3 {
          font-size: 0.9rem;
          margin-bottom: 0;
          align-self: center;
        }

        .badge {
          width: 100%;
          margin: 0;
        }
      }
    }

    .headerinfo {
      padding: 10px 20px;
      margin: 0px 10px;
      top: 0;
    }
    .testhead {
      padding: 20px 18px;
      top: 0;
    }

    .headerinfoTitle {
      padding: 0 0 10px;
    }
    .cardAutor {
      margin-top: 0;
      margin-right: 50px;
      margin-left: -10px;
      padding: "auto";
    }

    .editbtn {
      margin-top: 30px;
    }
    .Btn {
      margin: 0 5px;
    }
    .swipe {
      border-radius: 3%;
      padding: 0 10px;
    }

    .dekhla {
      position: relative;
      margin: 0;
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
    .dekhla img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
    }
    .dekhlatextheader h1 {
      position: absolute;
      width: 100%;
      height: auto;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
      font-style: normal;
      font-weight: 600;
      font-size: 3vw;

      text-align: center;
      text-transform: uppercase;

      color: #1e266d;
    }
    .dekhlatextheader h6 {
      position: absolute;
      width: 1178.82px;
      height: 50px;
      top: 23%;
      left: 50%;
      transform: translateX(-50%);
      font-style: normal;
      font-weight: 600;
      font-size: 2vw;

      text-align: center;
      text-transform: uppercase;

      color: #1e266d;
    }
  }
}

@media (max-width: 1024px) {
  .Ideainfo {
    .infosHeader {
      flex-direction: column;
      .Middle {
        padding: 30px;
        margin-left: 7%;
        // .imagesdiv {
        //   width: 93%;
        // }
      }
      .rightside {
        margin-left: 25%;
        margin-top: -14%;
        .slide {
          .slides {
            display: flex;
          }
        }
      }
      .ideaState {
        width: 90%;
        top: 88%;
      }
    }
    .ideabody {
      .DescCard {
        width: 59%;
        .teamSwiper {
          width: 100%;
        }
      }
      .ideaTags {
        .carta {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .Ideainfo {
    .infosHeader {
      flex-direction: column;
      .Middle {
        padding: 30px;
        margin-left: 7%;
        // .imagesdiv {
        //   width: 93%;
        // }
      }
      .rightside {
        margin-top: -83px;
      }
      .ideaState {
        top: 88%;
      }
    }
    .ideabody {
      flex-direction: column;
      .DescCard {
        width: 97%;
      }
      .ideaTags {
        .carta {
          width: 100%;
          margin-right: 20px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .Ideainfo {
    .infosHeader {
      flex-direction: column;
      .Middle {
        padding: 30px;
        margin-left: -7%;
        .imagesdiv {
          width: 302px;
        }
      }
      .rightside {
        width: 100%;
        margin-top: -116px;
        margin-left: -13%;
        .slide {
          width: 100%;
          margin-left: 39%;
          margin-top: -43%;
          .slides {
            display: flex;
            align-items: center;
            width: 100%;
          }
          .cursor {
            margin-top: 75%;
          }
        }
      }
      .ideaState {
        display: none;
        // width: 62%;
        // height: 75%;
        // top: 89%;
        // border-radius: 10px;
        // margin-left: 10%;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-around;
        // .statetrue {
        //   width: 174px;
        //   height: 112%;
        //   margin-left: 6%;
        // }
      }
    }
    .ideabody {
      flex-direction: column;
      .DescCard {
        width: 97%;
        margin-top: -26%;
      }
      .ideaTags {
        .carta {
          width: 100%;
          margin-right: 20px;
        }
      }
    }
  }
}
