.profiles-page {
  padding-top: 50px;
  .carta {
    padding: 20px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1) !important;
  }

  .search {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-input {
    border-radius: 10px;
  }
  .ant-btn {
    border-radius: 10px;
    margin: 0 10px;
  }
  .ant-select-selector {
    // width: 100%;
    border-radius: 10px !important;
  }
  .ant-picker {
    border-radius: 10px;
    height: 45px;
  }
  .langTag {
    width: 90%;
    margin: 5px 0;
    padding: 5px 20px;
    display: flex;
    color: white;
    background: #458ff6;
    border-radius: 20px;

    .close {
      cursor: pointer;
      margin-left: 10px;
    }
  }
}
