hr {
  border: 0 !important;
  background-color: black;
  height: 2px !important;
  width: 100px !important;
  margin: auto !important;
  margin-bottom: 50px !important;
}

.heading-comp {
  text-align: center !important;
}
