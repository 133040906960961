.myIdeas {
  width: 100%;
  padding-top: 100px;
  // height: 100%;
  position: relative;
  .pagination {
    width: 100%;
    margin-left: 22%;
    margin-top: 8%;
  }
  .bolisi {
    position: absolute;
    top: 300%;
    left: 50%;
    transform: translate((-50%, -50%));
  }
}
