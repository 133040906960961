.bod {
    justify-content: center;
    margin: 50px auto 0;
    width: 300px;
    height: 100px;
    position: relative;
  }
  
  .wrap-noresult {
    margin: 50px auto 0;
    width: 290px;
    height: 100px;
    position: relative;
  }
  
  .h2box {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 3em;
  }
  
  .items {
    font-size: 50px;
  }
  
  .items .fa {
    position: absolute;
    color: #458ff6;
    top: calc(50% - 0.4em);
    left: calc(50% - 0.4em);
    opacity: 0;
  }
  .items .fa:nth-child(1) {
    animation: slidefade 20s 0s infinite linear;
  }
  .items .fa:nth-child(2) {
    animation: slidefade 20s 2s infinite linear;
  }
  .items .fa:nth-child(3) {
    animation: slidefade 20s 4s infinite linear;
  }
  .items .fa:nth-child(4) {
    animation: slidefade 20s 6s infinite linear;
  }
  .items .fa:nth-child(5) {
    animation: slidefade 20s 8s infinite linear;
  }
  .items .fa:nth-child(6) {
    animation: slidefade 20s 10s infinite linear;
  }
  .items .fa:nth-child(7) {
    animation: slidefade 20s 12s infinite linear;
  }
  .items .fa:nth-child(8) {
    animation: slidefade 20s 14s infinite linear;
  }
  .items .fa:nth-child(9) {
    animation: slidefade 20s 16s infinite linear;
  }
  .items .fa:nth-child(10) {
    animation: slidefade 20s 18s infinite linear;
  }
  
  .search-1 {
    position: absolute;
    color: #458ff6;
    z-index: 2;
    font-size: 20px;
    top: 5px;
    left: 0;
    top: -10px;
    left: -15px;
  
    animation: rotcw 5s infinite linear;
  }
  .search-2 {
    position: absolute;
    color: #458ff6;
    z-index: 2;
    font-size: 12px;
    top: 10px;
    right: 15px;
    top: -5px;
    right: 0;
    animation: rotcw 4s infinite linear;
  }
  
  .search-3 {
    position: absolute;
    color: #458ff6;
    z-index: 2;
    font-size: 12px;
    bottom: 0;
    left: 5px;
    bottom: -15px;
    left: -10px;
    animation: rotccw 4.4s infinite linear;
  }
  
  .search-4 {
    position: absolute;
    color: #458ff6;
    z-index: 2;
    font-size: 20px;
    bottom: -5px;
    right: 0;
    bottom: -20px;
    right: -15px;
    animation: rotccw 5.5s infinite linear;
  }
  
  /**
     * Animations
     */
  
  @keyframes slidefade {
    0% {
      opacity: 0;
      transform: translate(50px, 0);
    }
    4.5% {
      opacity: 1;
      transform: translate(10px, 0);
    }
    10.5% {
      opacity: 1;
      transform: translate(-10px, 0);
    }
    15% {
      opacity: 0;
      transform: translate(-50px, 0);
    }
    100% {
      opacity: 0;
      transform: translate(50px, 0);
    }
  }
  
  @keyframes rotcw {
    from {
      transform: rotate(0deg) translate(5px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translate(5px) rotate(-360deg);
    }
  }
  
  @keyframes rotccw {
    from {
      transform: rotate(0deg) translate(5px) rotate(0deg);
    }
    to {
      transform: rotate(-360deg) translate(5px) rotate(360deg);
    }
  }