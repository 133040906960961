* {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.carta {
  height: 100%;
  padding: 20px;
  padding-top: 150px;
  background: #ffffff;
  /* drop shadow */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);

  border-radius: 20px;
  position: relative;
}

.carta-header {
  width: 100%;
  text-align: left;
}

.carta-img {
  position: absolute;
  top: 30px;
  left: 20px;
  text-align: left;
}

.ant-modal-content {
  border-radius: 20px !important;

  .ant-modal-header {
    border-radius: 20px 20px 0 0 !important;
    border: none;
    text-align: center;

    .ant-modal-title {
      font-weight: 1000;
    }
  }

  .ant-modal-body {
    padding: 40px;

    .ant-input {
      height: 50px;
      border-radius: 10px;
    }
  }

  .ant-modal-footer {
    padding-bottom: 20px;
    text-align: center;
    border: none;
    .ant-btn {
      height: 40px;
      border-radius: 10px;
    }
  }
}

.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.items-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-20 {
  margin-bottom: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.bg-red {
  background: red !important;
}
.w-100 {
  width: 100% !important;
}
.text-center {
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}
.ant-dropdown,
.ant-dropdown-menu {
  border-radius: 10px;
}