#carousel {
  height: 100px;
  width: 100%;
  // margin: auto;
  // margin: 0 30px;
}

.arrow {
  position: absolute;
  cursor: pointer;
  margin-top: 85px;
  z-index: 1000;
}

.arrow-right {
  left: 96.3%;
}

.item {
  text-align: center;
  position: absolute;
  transition: height 1s, width 1s, left 1s, margin-top 1s, background-image 1s;
}

.image {
  border-radius: 10px;
  background-image: url("https://images.unsplash.com/photo-1612831818795-bf68e721cfa0?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2166&q=80");
  background-size: cover;
  // object-fit: cover;
}

$level2-height: 50px;
$level2-width: 13.6%;
$level1-height: 80px;
$level1-width: 16%;
$level0-height: 100px;
$level0-width: 18.5%;

$level-2-left: 80.24%;
$level-1-left: 61.72%;
$level0-left: 40.74%;
$level1-left: 22.22%;
$level2-left: 6.17%;

.level-2 {
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  background-color: #228291;
  left: $level-2-left;
  margin-top: 25px;
}

.level-1 {
  height: $level1-height;
  width: $level1-width;
  line-height: $level1-height;
  background-color: #6796e5;
  left: $level-1-left;
  margin-top: 10px;
}

.level0 {
  height: $level0-height;
  width: $level0-width;
  line-height: $level0-height;
  background-color: #4ec9e1;
  left: $level0-left;
}

.level1 {
  height: $level1-height;
  width: $level1-width;
  line-height: $level1-height;
  background-color: #6796e5;
  margin-top: 10px;
  left: $level1-left;
}

.level2 {
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  background-color: #228291;
  margin-top: 25px;
  left: $level2-left;
}

.left-enter {
  opacity: 0;
  left: $level2-left - $level2-width;
  height: $level2-height - 30;
  width: $level2-width - 20;
  line-height: $level2-height - 30;
  margin-top: 40px;
}

.left-enter.left-enter-active {
  opacity: 1;
  left: $level2-left;
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  margin-top: 25px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s,
    line-height 1s;
}

.left-leave {
  opacity: 1;
  left: $level-2-left;
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  margin-top: 25px;
}

.left-leave.left-leave-active {
  left: $level-2-left + $level2-width + 20;
  opacity: 0;
  height: $level2-height - 30;
  line-height: 120px;
  margin-top: 40px;
  width: $level2-width - 20;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s,
    line-height 1s;
}

.right-enter {
  opacity: 0;
  left: $level-2-left + $level2-width;
  height: $level2-height - 30;
  width: $level2-width - 20;
  line-height: $level2-height - 30;
  margin-top: 40px;
}

.right-enter.right-enter-active {
  left: $level-2-left;
  opacity: 1;
  height: $level2-height;
  margin-top: 25px;
  line-height: $level2-height;
  width: $level2-width;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s,
    line-height 1s;
}

.right-leave {
  left: $level2-left;
  height: $level2-height;
  opacity: 1;
  margin-top: 25px;
  line-height: $level2-height;
  width: $level2-width;
}

.right-leave.right-leave-active {
  left: $level2-left - $level2-width;
  opacity: 0;
  height: $level2-height - 30;
  width: $level2-width - 20;
  line-height: $level2-height - 30;
  margin-top: 40px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s,
    line-height 1s;
}

.noselect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
