.complete-profile {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;

  svg {
    vertical-align: unset;
  }

  &__card {
    padding: 20px;
    margin-top: 50px;
    border-radius: 20px;
    box-shadow: 0px 5px 23px rgba(0, 0, 0, 0.15);
  }

  .form-card {
    min-height: 200px;
    padding: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;
  }

  .preview-container {
    width: 100%;
  }

  .preview-image {
    position: relative;
    margin: auto;
    width: 150px;
    height: 150px;
    cursor: pointer;

    .preview-remove {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 1;
    }
  }

  .dial-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .langTag {
    width: 90%;
    margin: 5px 0;
    padding: 5px 20px;
    display: flex;
    color: white;
    background: #458ff6;
    border-radius: 20px;

    .close {
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .skill {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;

    .close {
      cursor: pointer !important;
    }
  }

  .navigation-buttons {
    display: flex;
    justify-content: space-between;
  }

 

  button svg {
    vertical-align: middle;
    margin-right: 10px;
  }

  .experiences {
    &-header {
      color: gray;
    }

    .experience {
      margin: 10px 0;
    }
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-item {
      margin: 0 5px;
    }
  }
}
