@import "../variables";
@import "../global";

.convs-convs-loader {
	border-top: 1px solid #9999995c;
	overflow-y: scroll;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.convs-conv {
	display: flex;
	// width: 20% !important;
	justify-content: space-between;
	align-items: center;
	// margin: 5px 0;
	padding: 10px;
	cursor: pointer;
	transition: all 0.2s ease;

	.ant-avatar {
		min-width: 30px;
		min-height: 30px;
		max-width: 30px;
		max-height: 30px;
	}

	&:hover {
		background: #f7f7f7;
	}

	.name-last_message {
		width: 80% !important;
		.name {
			// background: red;
			font-weight: 700;
			font-size: 13px;
			// background: red;
		}

		.last_message {
			font-weight: 300;
			font-size: 10px;
			color: #999999;

			&-unread {
				color: $primary;
			}
		}
	}
	.unread {
		// width: 25px;
		// display: inline-block;
		&-circle {
			padding: 5px;
			width: 20px;
			height: 20px;
			font-size: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 10px;
			background: $primary;
			color: white;
		}
	}
}
