@import "../global";
@import "../variables";

.incubator-page {
	padding-top: 100px;
	background-color: white;
	height: 100%;
	.header {
		text-align: center;
		background-image: linear-gradient(to top, white, rgba(0, 0, 0, 0));
		// .main-svg {
		// 	width: 80%;
		// 	margin: auto;
		// }
	}
	.section__main {
		height: 427px;
		background: linear-gradient(180deg, rgba(242, 249, 255, 0.12) 0%, #f2f9ff 100%);
		display: flex;
		padding-top: 130px;
		margin-bottom: 100px;
		align-items: center;
		justify-content: flex-end;

		&--main-svg {
			width: 100%;
			align-self: flex-end;
			margin-top: 35px;
		}

		&--slogan,
		&--title {
			text-align: center;
			text-transform: uppercase;
			color: #1e266d;
		}
	}
	.carta {
		padding: 20px;
		// margin: 20px 0;
		&-nav {
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 700;
			color: $primary;
			text-transform: uppercase;
			cursor: pointer;
			transition: all 0.15s ease-in-out;

			&-disabled {
				opacity: 0.8;
				box-shadow: none;
				color: #999999;
				cursor: not-allowed;

				&:hover {
					background: white !important;
					color: #999999 !important;
				}
			}

			&:hover {
				background: $primary;
				color: white;
			}
		}
	}

	.idea-selector {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;

		.ant-select {
			width: 100% !important;
		}

		.team-leader {
			width: 80%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			// align-items: center;
			// margin: 0 5px;

			.name {
				font-weight: 700;
				font-size: 15px;
				text-transform: capitalize;
				line-height: 20px;
			}

			.all-members {
				font-size: 12px;
				font-weight: 300;
				color: #999999;
				cursor: pointer;
				line-height: 20px;
			}
		}

		&-start {
			color: #999999;
			font-size: 12px;
		}
	}

	.heading {
		text-align: center;
		font-weight: 700;
		font-size: 25px;
		margin: 50px 0;

		hr {
			height: 1px !important;
		}
	}
	.example {
		margin: 20px 0;
		margin-bottom: 20px;
		padding: 30px 50px;
		text-align: center;
		background: rgba(0, 0, 0, 0.05);
		border-radius: 4px;
	}
}
.timeline {
	width: 100%;
	height: 500px;
	overflow: auto;
	padding: 10px;
	padding-right: 20px;
	.ideacreated {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: auto;
		padding: 10px 15px;
		border: 0;
		border-radius: 20px !important;
		align-items: flex-start;
		background: #ffff;
		box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1) !important;
		transition: transform 0.2s ease;
		.action {
			font-weight: 300;
			font-size: 14px;
			line-height: 18px;
			color: #585555;
		}
		.createdby {
			display: flex;
			flex-direction: row;
			margin-top: 15px;
			.Ellipse_224 {
				border: 2px solid #458ff6;
				border-radius: 50%;
				background-image: url("https://images.unsplash.com/photo-1554080353-a576cf803bda?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1534&q=80");
				background-size: cover;
				width: 32px;
				min-width: 32px;
				height: 32px;
				min-height: 32px;
				margin: 0 5px;
			}
			.user-infos {
				.name {
					font-weight: 700;
					font-size: 12px;
					line-height: 18px;
					color: #000000;
					text-transform: capitalize;
				}
				.position {
					font-weight: 300;
					font-size: 14px;
					line-height: 18px;
					color: #585555;
				}
			}
		}
	}
}

.Desc {
	width: 100%;
	font-weight: 300;
	font-size: 14px;
	line-height: 18px;
	color: #585555;
}
