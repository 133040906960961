@import "variables";

.footer_ {
	display: flex;
	justify-content: end;
	align-items: center;
	margin-top: 20px;
	.socials {
		display: flex;
		justify-self: unset;
		align-items: end;
		margin-right: 20px;
		flex-direction: row;
		padding: 5px;
		justify-content: space-between;
		.social-icon {
			width: 30px;
			height: 22px;
			margin: 0 5px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.main-app-layout {
	.collapse-menu {
		cursor: pointer;
	}

	.main-app-navbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;

		&-left,
		&-right {
			display: flex;
			align-items: center;
		}
		&-item {
			cursor: pointer;
			transition: color 0.15s ease-in-out;
		}

		&-item:hover {
			color: $primary;
		}

		&-left {
			.main-app-navbar-item {
				margin: 0 10px;
			}
		}

		&-right {
			.main-app-navbar-item {
				margin: 0 15px;
			}
		}
	}

	.ideas-text {
		margin-left: 10px;
	}

	.avatar-chevron {
		margin-right: unset;
		margin-left: 5px;
	}
}

.sider {
	max-width: 260px !important;
	width: 260px !important;
	padding-bottom: 30px !important;
	overflow: "auto" !important;
	height: "100vh" !important;
	position: "fixed" !important;
	left: 0;
}
.ant-layout-sider .ant-layout-sider-light {
	max-width: 260px !important;
	width: 260px !important;
}
.ant-layout-sider-collapsed {
	width: 80px !important;
	width: 80px !important;
}
.popover-item {
	width: 100%;
	padding: 5px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	transition: color 0.15s ease-in-out;
}

.popover-item:hover {
	color: $primary;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sider::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sider {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.ant-popover-inner-content {
	padding: 0 !important;
}

.ant-popover-inner {
	border-radius: 15px;
	overflow: hidden;
}

// overflowX: "hidden",
//             height: "100vh",
//             position: "fixed",
//             left: 0,
//             zIndex: 1,

// @media screen and (max-width: 1024px) {
//   // iPad portrait
//   .sider {
//     overflow-x: hidden;
//     position: fixed;
//     right: unset;
//     left: 0;
//     z-index: 1;
//     width: 0 !important;
//     max-width: 0 !important;
//   }
//   // .inner-layout {
//   //   padding-right: unset;
//   //   padding-left: 30px;
//   // }
// }
@media screen and (max-width: 1026px) {
	// iPad portrait
	.sider {
		overflow-x: hidden;
		position: fixed;
		right: unset;
		left: 0;
		z-index: 1;
		width: 0 !important;
		max-width: 0 !important;
	}
	// .inner-layout {
	//   padding-right: unset;
	//   padding-left: 30px;
	// }
}

html[dir="rtl"] {
	.sider {
		left: unset;
		right: 0;
	}
	.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
		padding-left: 161px;
	}
	@media screen and (max-width: 768px) {
		.sider {
			left: unset;
			right: 0;
			background-color: red;
		}
		// .inner-layout {
		//   padding-right: 30px;
		//   padding-left: unset;
		// }
	}

	.avatar-chevron {
		margin-right: 5px;
		margin-left: unset;
	}
}
