$misc-inner-max-width: 750px;

.misc-wrapper {
	display: flex;
	flex-basis: 100%;
	min-height: 100vh;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	.leafunder-logo {
		height: 100px;
		margin-top: 10px;
	}

	.misc-inner {
		position: relative;
		max-width: $misc-inner-max-width;
	}
}

@media screen and (max-width: 459px) {
	.misc-wrapper {
		.leafunder-logo {
			margin-top: 25px !important;
		}
	}
}
