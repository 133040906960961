@import "~antd/dist/antd.css";

html[dir="ltr"] body {
	text-align: left;
	font-family: "Mulish", sans-serif !important;
}

html[dir="rtl"] body {
	text-align: right;
	font-family: "Tajawal", sans-serif !important;
	.google {
		background-color: #dd4b39;
		&:hover {
			background-color: darken(#dd4b39, 10%);
		}
		&:before {
			content: "";
			padding-left: 1rem;
		}
		img {
			width: 20px;
			height: 20px;
			margin-right: -31px;
			margin-left: 20px;
		}
	}
}
#zmmtg-root {
	display: none;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-decoration: none !important;
}

html,
body {
	font-size: unset !important;
}

#root {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
}

.heading-1 {
	font-weight: 700;
	font-size: 48px;
	line-height: 56px;
}

.heading-2 {
	font-weight: 700;
	font-size: 25px;
	line-height: 56px;
}

.heading-3 {
	font-weight: 700;
	font-size: 24px;
	line-height: 34px;
}

.lead {
	font-weight: 300;
	font-size: 1.25rem !important;
	line-height: 32px;
	color: #7d7987;
	text-align: left;
}

.button {
	display: inline-block !important;
	padding: 10px 30px !important;
	border-radius: 10px !important;
	margin: 5px 10px !important;
	cursor: pointer !important;
	background: unset !important;
	text-align: center !important;
}
.google {
	background-color: #dd4b39;
	&:hover {
		background-color: darken(#dd4b39, 10%);
	}
	&:before {
		content: "";
		padding-right: 1rem;
	}
	img {
		width: 20px;
		height: 20px;
		margin-left: -31px;
		margin-right: 20px;
	}
}

.bf-controlbar .control-item.button {
	padding: 0 2px !important;
}

.button-primary-fill {
	background-color: #458ff6 !important;
	color: white !important;
}

.button-primary-fill:disabled {
	background-color: #8ebcfd !important;
	color: white !important;
	cursor: auto !important;
}

.button-primary-stroke {
	color: #7d7987 !important;
	border: 1px solid #458ff6 !important;
}

.button-primary-stroke:disabled {
	color: #bdb9c5 !important;
	border: 1px solid #a3c8fd !important;
	cursor: auto !important;
}

.icon {
	vertical-align: middle;
}

.sweet-alert-buttons {
	width: 100%;
}

/* *** outline *** */

// * {
//   outline-width: 2px !important;
//   outline-color: rgba(0, 0, 0, 0.4) !important;
//   transition: outline 0.5s ease-in-out !important;
// }

/*******************/

.toast-test {
	background: red;
}

.languages-button {
	display: inline-block;
	// box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
}

.loading-screen {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
