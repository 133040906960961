.person-card {
  width: 200px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 20px;
  text-align: center;
}

.person-pdp {
  width: 90px;
  height: 90px;
  border-radius: 90px;
  margin: 20px auto;
}

.person-desc {
  margin: 10px 0;
  font-size: 10px;
}

.person-socials {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.message {
  font-size: 12px;
  padding: 5px 30px;
  display: table;
  margin: 20px auto;
  border-radius: 8px;
}