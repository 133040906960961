@import "../variables";
@import "../global";

.ManageTeam {
  .carta {
    padding: 20px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1) !important;
  }

  .form-control {
    height: 60px !important;
    font-size: 20px;
    padding-left: 20px !important;
    width: 90% !important;
    margin: auto;
    border-radius: 10px;
  }

  .suggestion-item {
    cursor: unset !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-info {
      display: flex;
      align-items: center;
      .user-image {
        margin: 0 5px;
        width: 50px;
        height: 50px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50px;
      }
      .user-name {
        margin: 0 5px;
        font-weight: 700;
        text-transform: capitalize;
      }
    }

    .send-invite {
      color: $primary;
      cursor: pointer;
    }
  }

  .AllRequests {
    .user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &Image {
        width: 50px;
        height: 50px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50px;
      }
      &Infos {
        .username {
          font-weight: 700;
        }
        .position,
        .member {
          color: rgb(192, 192, 192);
          font-size: 12px;
        }
      }
      .status {
        width: 75px;
        background: gray;
        color: white;
        font-size: 12px;
        border-radius: 10px;
        padding: 5px 10px;
      }
      .Pending {
        background: #ff8f27;
      }
      .Rejected {
        background: #ff0000;
      }
    }
  }

  .editMembers {
    margin: 10px 0;
    padding: 0 10px;
    .user {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &user {
        display: flex;
        align-items: center;
      }

      &Image {
        width: 50px;
        height: 50px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50px;
        margin: 0 10px;
      }

      &Infos {
        .username {
          font-weight: 700;
        }
        .position,
        .member {
          color: rgb(192, 192, 192);
          font-size: 12px;
        }
      }
    }
  }

  .no-request {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(207, 207, 207);
  }
}
