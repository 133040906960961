@import "../variables";

.profile-page {
	.row {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.profile-cover {
		height: 200px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		position: relative;
	}

	.cover-infos {
		position: absolute;
		bottom: 10px;
		left: unset;
		right: 10px;
		display: flex;
		align-items: center;

		.cover-info {
			margin: 0 2px;
			cursor: pointer;
		}

		.edit-profile {
			padding: 5px 10px;
			border-radius: 10px;
		}
	}

	.section {
		background: white;
		height: 100%;
		width: 100%;
		border-radius: 10px;
		padding: 10px;
		position: relative;
		margin: 0;
		box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
	}

	.main-infos {
		padding-top: 75px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		.profile-picture {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			width: 150px;
			height: 150px;
			border-radius: 100px;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -60%);
			border: 3px solid $primary;
		}

		.online-status {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			.online-dot {
				width: 10px;
				height: 10px;
				margin: 0 5px;
				border-radius: 10px;
			}

			.online-text {
				font-size: 12px;
			}
		}

		.full-name {
			font-size: 20px;
			font-weight: 700;
			color: $primary;

			span {
				text-transform: capitalize;
			}
		}

		.location {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			svg {
				margin: 0 5px;
			}

			span {
				font-size: 12px;
			}
		}

		.infos-grid {
			width: 90%;

			&-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 11px;
			}
		}
	}

	.biography {
		&-heading {
			color: $primary;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 5px;
		}
	}

	.exp-edu-heading {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		span {
			font-size: 15px;
			font-weight: 700;
			text-transform: uppercase;
			margin-left: 10px;
		}
	}

	.exps {
		margin-bottom: 40px;
		.exp {
			display: flex;
			align-items: center;

			.org {
				&-img {
					width: 50px;
					height: 50px;
					background-image: url("../../assets/App/Profiles/qualitative-research.png");
					background-size: cover;
					// background-color: white;
					margin-right: 10px;
				}
				&-infos {
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.post {
						font-weight: 700;
						font-size: 15px;
					}
					.duration {
						font-weight: 400;
						font-size: 12px;
					}
					.org {
						font-weight: 200;
						font-size: 10px;
						color: grey;
					}
				}
			}
			.std {
				&-img {
					width: 50px;
					height: 50px;
					background-image: url("../../assets/App/Profiles/earth.png");
					background-size: cover;
					// background-color: white;
					margin-right: 10px;
				}
				&-infos {
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.post {
						font-weight: 700;
						font-size: 15px;
					}
					.duration {
						font-weight: 400;
						font-size: 12px;
					}
					.std {
						font-weight: 200;
						font-size: 10px;
						color: grey;
					}
				}
			}
		}
	}

	.message-nursery {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin: 5px 0 10px 0;
	}

	.ant-btn {
		border-radius: 5px;
		padding: 5px 10px;
		font-size: 12px;
		border: 2px solid $primary;
		color: $primary;

		&-primary {
			color: white;
			border: none;
		}
	}

	.questions {
		padding: 20px;

		.question {
			margin-top: 10px;
			color: $primary;
			font-weight: 700;
			margin-bottom: 5px;
			font-size: 15px;
		}

		.answer {
			color: black;
			font-weight: 300;
			font-size: 12px;
			margin-bottom: 5px;
		}
	}

	.ideas {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&-heading {
			color: $primary;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 5px;
			text-align: center;
		}

		.swiper-end {
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}
	}

	.no-request {
		display: flex;
		justify-content: center;
		align-items: center;
		color: rgb(228, 228, 228);
		margin: 20px 0;
	}

	.type-tag {
		padding: 5px 10px;
		border-radius: 5px;
		font-size: 12px;
		display: flex;
		background-color: rgb(190, 190, 190);
		position: absolute;
		top: 20px;
		left: 50%;
		// trans
	}
}

.loadingUser {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

html[dir="rtl"] {
	.cover-infos {
		right: unset;
		left: 10px;
	}
}

@media screen and (max-width: 425px) {
	.profile-page {
		.cover-infos {
			position: absolute;
			bottom: 140px;
			top: 0;
			left: unset;
			right: 10px;
			display: flex;
			align-items: center;
		}
	}
}
