@import "../global";
@import "../variables";

.menuBar {
    width: 100%;
    padding: 10px !important;
    min-height: 80px;
    background-color: white;
    border-radius: 10px;
    margin-top: 3%;
    .MenuButton {
      width: 100%;
      height: 100%;
      min-height: 50px;
      box-shadow: 0px 0px 10px rgba(186, 188, 194, 0.5);
      border-radius: 10px;
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 27px;
      text-align: center;
      color: #458ff6;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .active {
      background-color: #eef5ff;
    }
  }